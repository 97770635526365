import { rest } from 'msw'
import { ENDPOINTS } from '@/base/const/endpoints.const'
import {
  CANCEL_VISAMOBILE_TRANSACTION,
  CONFIRMATION,
  PAYMENT_REJECT,
  PAYMENT_START_RECURRING,
  PAYMENT_START_VISA_MOBILE,
  PAYMENT_UPDATE_VISA_MOBILE,
  PAYMENT_WAIT,
  SESSION_GET_REMAINING_TIME,
  THANK_YOU_START, PAYMENT_START_GL30, PAYMENT_CONTINUE_429, BANERS, PAYMENT_LANGUAGE_DATA
} from '@/mock/fixtures'

export default [
  rest.post(ENDPOINTS.start, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_START_GL30))
  }),
  rest.post(ENDPOINTS.ads.getBannersList, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(BANERS))
  }),
  rest.get(ENDPOINTS.languageData, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_LANGUAGE_DATA))
  }),
  rest.post(ENDPOINTS.visadata, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_START_VISA_MOBILE))
  }),
  rest.post(ENDPOINTS.session.reject, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_REJECT))
  }),
  rest.post(ENDPOINTS.wait.getData, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_WAIT))
  }),
  rest.post(ENDPOINTS.continue, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_CONTINUE_429), ctx.status(429))
  }),
  // rest.post(ENDPOINTS.continue, (req, res, ctx) => {
  //   return res(ctx.delay(500), ctx.json(PAYMENT_CONTIUNE_ORANGEDOP))
  // }),
  rest.post(ENDPOINTS.updatePayway, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_UPDATE_VISA_MOBILE))
  }),
  rest.post(ENDPOINTS.session.getRemainingTime, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(SESSION_GET_REMAINING_TIME))
  }),
  rest.post(ENDPOINTS.session.refresh, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(SESSION_GET_REMAINING_TIME))
  }),
  rest.post(ENDPOINTS.regulationsApprovalStart, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(PAYMENT_START_RECURRING))
  }),
  rest.post(ENDPOINTS.thankYou.getData, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(THANK_YOU_START))
  }),
  rest.post(ENDPOINTS.confirmation, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(CONFIRMATION))
  }),
  rest.post(ENDPOINTS.cards.visaMobile.cancel, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(CANCEL_VISAMOBILE_TRANSACTION))
  })/* ,
  rest.get(ENDPOINTS.ads, (req, res, ctx) => {
    return res(ctx.delay(500), ctx.json(ADS_BANNER))
  }) */
]
